import { LicenseRequest, OrganizationTypes } from "integrity-owls-logic/dist";
import React, { useState, useEffect } from "react";
import { profileService, stripeService } from "../services";

type CartContextType = {
  selectedCartItems: Array<LicenseRequest>;
  orderId: string | null;
  updateOrderId: (newOrderID: string | null) => void;
  updateSelectedCartItems: (selectedCartItems: Array<LicenseRequest>) => void;
};

export const CartContext = React.createContext<CartContextType | null>(null);

export const CartProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [selectedCartItems, setSelectedCartItems] = useState<
    Array<LicenseRequest>
  >([]);
  const [orderId, setOrderId] = useState<string | null>(null);

  const getIncompleteOrder = async () => {
    const order = await stripeService.getIncompleteOrder();

    // handle batching
    if (order && order.id) {
      setSelectedCartItems(order.licenseRequests);
      setOrderId(order.id);
    } else {
      // if no order exists create new one.
      updateStripeOrder([]);
    }
  };

  const getIncompleteOrderIfUser = async () => {
    // not ideal to fetch this here, but we don't have access to redux in here, and the
    // useEffect is running on app load, for every user. Need to fetch profile to guard
    // against bad stripe transactions for internal users
    const profile = await profileService.fetchMyProfile();
    // only want this to execute if the organization isn't internal, because
    // they'll never be checking out. This code gets evaluated for every user
    // because of how everything is being loaded w/ Suspense, which is its own other thing.
    if (
      profile &&
      profile.organization &&
      profile.organization.organizationType !== OrganizationTypes.INTERNAL
    ) {
      if (orderId === null) {
        getIncompleteOrder();
      }
    }
  };

  //need use effect to fetch initial values
  useEffect(() => {
    getIncompleteOrderIfUser();
  }, [getIncompleteOrderIfUser]);

  const updateStripeOrder = async (
    cartItems: Array<LicenseRequest>,
    description?: string
  ) => {
    try {
      const response = await stripeService.createOrUpdateOrder({
        description: description || "",
        licenseRequestIds: cartItems.map(item => item.id),
        orderId: orderId
      });
      console.log("updateStripeOrder", response);
      setOrderId(response.data);
    } catch (err) {
      console.log("error updating Stripe Order from context", err);
    }
  };

  const updateOrderId = (newOrderID: string | null) => {
    if (newOrderID === null) {
      getIncompleteOrder();
    } else if (orderId !== newOrderID) {
      console.log("setting new order ID: ", newOrderID);
      setOrderId(newOrderID);
    }
  };

  const updateSelectedCartItems = (
    newSelectedCartItems: Array<LicenseRequest>,
    description?: string
  ) => {
    console.log("update provider", newSelectedCartItems);
    setSelectedCartItems(newSelectedCartItems);
    updateStripeOrder(newSelectedCartItems, description);
  };

  const contextValue = {
    selectedCartItems,
    orderId,
    updateOrderId,
    updateSelectedCartItems
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};

export function useCartContext() {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCartContext must be used within a CartContextProvider");
  }
  return context;
}
