import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import account from "./account";
import app from "./app";
import licenseRequest from "./licenseRequest";
import product from "./product";
import profile from "./profile";
import project from "./project";
import search from "./search";
import {
  ReduxAccountState,
  ReduxAppState,
  ReduxLicenseRequestState,
  ReduxProductState,
  ReduxProfileState,
  ReduxProjectState,
  ReduxSearchState
} from "../../interfaces/Shared";

export interface CombinedReducerState {
  account: ReduxAccountState;
  router: RouterState;
  app: ReduxAppState;
  licenseRequest: ReduxLicenseRequestState;
  product: ReduxProductState;
  profile: ReduxProfileState;
  project: ReduxProjectState;
  search: ReduxSearchState;
}

export default (history: any) =>
  combineReducers({
    account,
    router: connectRouter(history),
    app,
    licenseRequest,
    product,
    profile,
    project,
    search
  });
