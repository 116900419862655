import {
  SET_LICENSES_PAGE,
  SET_QUEUE_SUMMARY,
  SET_LICENSES_ROWS_PER_PAGE,
  SET_LICENSES_SEARCH_TEXT,
  SET_LICENSES_SELECTED_ORGANIZATION,
  SET_LICENSES_SELECTED_PROJECT,
  SET_LICENSES_SELECTED_TAB,
  SET_LICENSES_SELECTED_USER_ID,
  SET_LICENSES_TOTAL_RECORDS,
  SET_LICENSES_SORT_BY,
  SET_LICENSES_SORT_DIRECTION,
  SET_LICENSES_BEGIN_FILTER_DATE,
  SET_LICENSES_END_FILTER_DATE,
  SET_LICENSES_SELECTED_LICENSE_TYPE_ID
} from "../../constants";
import { ReduxLicenseRequestState } from "../../interfaces/Shared";

const initialState: ReduxLicenseRequestState = {
  index: {
    page: 0,
    rowsPerPage: "5",
    searchText: "",
    selectedOrganization: "",
    selectedProject: "",
    selectedTab: "All Licenses",
    selectedUserId: null,
    selectedLicenseTypeId: null,
    totalRecords: 10,
    sortBy: "requestedDate",
    sortDirection: "asc",
    beginFilterDate: null,
    endFilterDate: null
  },
  queue: [],
  queueSummary: {
    items: [],
    size: 0,
    hasLicense: false,
    hasApprovedLicense: false
  }
};

function licenseRequest(state = initialState, action: any) {
  switch (action.type) {
    case SET_LICENSES_PAGE:
      const stateWithNewPage = Object.assign({}, state);
      stateWithNewPage.index.page = action.payload;
      return stateWithNewPage;
    case SET_LICENSES_ROWS_PER_PAGE:
      const stateWithNewRowsPerPage = Object.assign({}, state);
      stateWithNewRowsPerPage.index.rowsPerPage = action.payload;
      return stateWithNewRowsPerPage;
    case SET_LICENSES_SEARCH_TEXT:
      const stateWithNewSearchText = Object.assign({}, state);
      stateWithNewSearchText.index.searchText = action.payload;
      return stateWithNewSearchText;
    case SET_LICENSES_SELECTED_ORGANIZATION:
      const stateWithNewSelectedOrganization = Object.assign({}, state);
      stateWithNewSelectedOrganization.index.selectedOrganization =
        action.payload;
      return stateWithNewSelectedOrganization;
    case SET_LICENSES_SELECTED_PROJECT:
      const stateWithNewSelectedProject = Object.assign({}, state);
      stateWithNewSelectedProject.index.selectedProject = action.payload;
      return stateWithNewSelectedProject;
    case SET_LICENSES_SELECTED_TAB:
      const stateWithNewSelectedTab = Object.assign({}, state);
      stateWithNewSelectedTab.index.selectedTab = action.payload;
      return stateWithNewSelectedTab;
    case SET_LICENSES_SELECTED_USER_ID:
      const stateWithNewSelectedUserId = Object.assign({}, state);
      stateWithNewSelectedUserId.index.selectedUserId = action.payload;
      return stateWithNewSelectedUserId;
    case SET_LICENSES_SELECTED_LICENSE_TYPE_ID:
      const stateWithNewSelectedLicenseTypeId = Object.assign({}, state);
      stateWithNewSelectedLicenseTypeId.index.selectedLicenseTypeId =
        action.payload;
      return stateWithNewSelectedLicenseTypeId;
    case SET_LICENSES_TOTAL_RECORDS:
      const stateWithNewTotalRecords = Object.assign({}, state);
      stateWithNewTotalRecords.index.totalRecords = action.payload;
      return stateWithNewTotalRecords;
    case SET_LICENSES_SORT_BY:
      const stateWithNewSortBy = { ...state };
      stateWithNewSortBy.index.sortBy = action.payload;
      return stateWithNewSortBy;
    case SET_LICENSES_SORT_DIRECTION:
      const stateWithNewSortDirection = { ...state };
      stateWithNewSortDirection.index.sortDirection = action.payload;
      return stateWithNewSortDirection;
    case SET_LICENSES_BEGIN_FILTER_DATE:
      const stateWithNewBeginFilterDate = { ...state };
      stateWithNewBeginFilterDate.index.beginFilterDate = action.payload;
      return stateWithNewBeginFilterDate;
    case SET_LICENSES_END_FILTER_DATE:
      const stateWithNewNewEndFilterDate = { ...state };
      stateWithNewNewEndFilterDate.index.endFilterDate = action.payload;
      return stateWithNewNewEndFilterDate;
    case SET_QUEUE_SUMMARY:
      return Object.assign({}, state, {
        queueSummary: action.payload
      });
    default:
      return state;
  }
}

export default licenseRequest;
